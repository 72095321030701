/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Iqbal Wahyudi Pratama - iqbalwahyudip.com
==============================================================================================*/
@import "sass-lib";

@media(min-width: 1px) and (max-width: 1730px){
	.wrapper-lg{width: 1366px;}

	header{
		.nav_header{
			width: 1060px;
			.nav_left{
				li{margin: 0 20px;}
			}
		}
	}
}

@media(min-width: 1px) and (max-width: 1500px){
	.wrapper-lg{width: 1240px;}
	.box-title{
		margin: 44px 0 68px;
		h6{margin-bottom: 15px;}
	}

	header{
		.nav_header{
			width: 960px;
			.nav_left{
				li{margin: 0 15px;}
			}
		}
	}
	.box-news-home{
		.box-title-news{width: 19%;}
		.box-news-list-home{width: 78%;}
	}
	.slidenews{
		.desc{
			width: 960px; left: 50%; top: 50%; bottom: auto; @include transform(translate(-50%, -50%));
			.wrapper{width: 100%;}
		}
		.slick-prev{left: 5%;}
		.slick-next{right: 5%;}
	}
	.sec-news-list{padding: 40px 0;}
	.box-detail-news{
		.box-title{margin-top: 0;}
	}
	.sliderblog-lg{
		.list{
			.desc{
				padding: 0 40px 30px;
				span{font-size: 16px;margin-bottom: 5px;}
				h2{font-size: 36px;line-height: 40px;max-height: 80px;}
			}
		}
	}
	.testiHome {
		.wrapper {width: 1240px;}
	}
	.ls-Testi {
		.box_testi {
			h3 {font-size: 20px; margin-bottom: 15px;}
			h4 {font-size: 18px; margin-bottom: 15px;}
		 	p {font-size: 15px;}
		}
	}
}
@media(min-width: 1px) and (max-width: 1380px){
	h1{font-size: 30px;}
	h4{font-size: 22px;}
	.btn-oval{
		height: 40px; line-height: 40px; font-size: 12px;
		&.btn-submit{height: 50px;}
	}
	.hline .line-yl, .hline footer .foot-top .box-link-foot h5:before, footer .foot-top .box-link-foot .hline h5:before{left: -40px;}
	.box-proteksi .desc-proteksi h3.hline .line-yl, .box-proteksi .desc-proteksi h3.hline footer .foot-top .box-link-foot h5:before, footer .foot-top .box-link-foot .box-proteksi .desc-proteksi h3.hline h5:before{left: -40px;}
	.box-proteksi .right .desc-proteksi h3.hline .line-yl, .box-proteksi .right .desc-proteksi h3.hline footer .foot-top .box-link-foot h5:before, footer .foot-top .box-link-foot .box-proteksi .right .desc-proteksi h3.hline h5:before{right: -40px;}
	.box-board .list-board:nth-child(2n) .hline .line-yl, .box-board .list-board:nth-child(2n) .hline footer .foot-top .box-link-foot h5:before, footer .foot-top .box-link-foot .box-board .list-board:nth-child(2n) .hline h5:before{right: -40px;}


	header{
		height: 84px;
		.logo{
			img{width: 140px;}
		}
		.nav_header{
			margin-top: 45px; width: 920px;
			.nav_left{
				/*li{margin: 0 18px;}*/
			}
		}
		&.float{
			height: 68px;
	        .logo{
	            img{width: 120px;}
	        }
	        .nav_header{margin-top: 35px;}
	    }
	}
	.slidehome{
		.list-slide{
			padding-top: 85px;
			.desc{
				h1{font-size: 45px; margin-bottom: 35px;}
			}
		}
	}
	.box-banner-registration{
		.left{padding-top: 12px;}
		.btn-pink{font-size: 16px;}
	}
	.box-proteksi{
		.center{
			.oval{font-size: 12px; width: 160px; height: 22px; line-height: 24px; padding: 0 10px}
		}
		.bg-proteksi{height: 280px;}
		.img-proteksi{width: 43.5%;}
		.desc-proteksi{
			height: 280px;
			h3{font-size: 24px;}
			h6{font-size: 13px; line-height: 13px;}
			p{font-size: 14px; line-height: 18px; margin-bottom: 20px;}
		}
	}
	.sec-about{
		.right{
			h1{margin-bottom: 15px;}
			p{margin-bottom: 30px; font-size: 14px;}
		}
	}
	.box-banner-solution{
		padding: 50px 0;
		h2{font-size: 22px;}
	}
	.box-news-home{
		padding: 55px 0 62px;
		.box-title-news{
			h1{line-height: 30px; margin-bottom: 20px;}
		}
	}
	.box-news-list-home{
		.list{
			.desc{
				h4{font-size: 18px; line-height: 22px;}
			}
		}
	}
	.banner,.margtophead{margin-top: 85px;}
	.paging{margin: 50px 0;}
	.box-two-list{
		.list{
			.left{width: 45%;}
			.right{width: 47%;}
		}
	}

	footer{
		.foot-top{
			font-size: 14px; padding: 40px 0 30px;
			.box-link-foot{
				h5{font-size: 16px;}
			}
		}
		.foot-bottom{
			font-size: 14px; padding: 10px 0 15px;
		}
	}
	.section-telemarketing .form-cek .cek-number form {width: 380px;}
}

@media(min-width: 1px) and (max-width: 1300px){
	h3{font-size: 25px; line-height: 30px;}
	h5{font-size: 16px;}
	.wrapper-lg, .wrapper{width: 905px;}
	.btn-oval{
		padding-left: 20px; padding-right: 20px;
		&.btn-submit-big{padding: 0 60px;}
	}

	.icwp{
		&.ic_proteksi, &.ic_simulasi{background-size: 100% 200%; width: 18px; height: 21px;}
	}
	.box-title{
		padding-left: 280px; margin: 44px 0;
		h2{font-size: 26px; line-height: 32px;}
		h6{margin-bottom: 10px;}
		.line-vly{left: 240px;height: 190px;}
		.date-title{margin-bottom: 15px;}
	}


	.row_list{
        .col{
            &.col_mid_full{width: 100%;}
            &.col_mid_8{width: 80%;}
            &.col_mid_7{width: 70%;}
            &.col_mid_66{width: 66.66666666666666%;}
            &.col_mid_6{width: 60%;}
            &.col_mid_55{width: 55%;}
            &.col_mid_5{width: 50%;}
            &.col_mid_45{width: 45%;}
            &.col_mid_4{width: 40%;}
            &.col_mid_35{width: 35%;}
            &.col_mid_33{width: 33.3333333333333%;}
            &.col_mid_3{width: 30%;}
            &.col_mid_25{width: 25%;}
            &.col_mid_2{width: 20%;}
            &.col_mid_16{width: 16.66666666666666%;}
            &.col_mid_1{width: 10%;}
        }
    }

	header{
		height: 65px;
		.logo{
			padding: 4px 0 8px;
			img{width: 132px;}
		}
		.nav_header{
			width: 715px; margin-top: 35px;
			.nav_left{
				li{
					margin: 0 10px;
					&:first-child{margin-left: 0;}
					&:last-child{margin-right: 0;}
				}
			}
			.nav_right{padding-top: 3px;}
		}
		&.float{
			height: 65px;
	        .logo{
	            padding: 4px 0 8px;
	        }
			.btn-buy {margin-top: -2px;}
	    }
	    .btn-buy{
	    	font-size: 0px; color: transparent; background-color: transparent; background-image: url('../images/material/ic_cart_2.png'); background-repeat: no-repeat; background-position: center; background-size: 100%;
	    	padding: 0; width: 25px; height: 25px; margin-top: -2px; @include borderRadius(0);
	    }
	}

	.cart-link{width: 20px; height: 20px;}

	.slidehome{
		.list-slide{
			padding-top: 65px;
			.desc{width: 660px;}
		}
	}

	.box-banner-solution{
		padding: 40px 0;
		h2{
			&.hline{
				.line-yl{display: none;}
			}
		}
		.left{
			width: 54%;
			p{font-size: 14px;}
		}
		.right{
			width: 45%;
			.btn-oval{margin-left: 10px;}
		}
	}
	.box-search{
		input[type=text]{width: 50px; font-size: 12px;}
		.btn-search{background-size: cover; width: 15px; height: 15px; padding: 0; position: relative;top: 3px;}
	}
	.box-proteksi{
		.desc-proteksi{
			h3{font-size: 16px; line-height: 20px;}
			p{font-size: 12px; line-height: 13px;}
		}
	}
	.sec-about{
		.right{@include boxSizing(border-box); padding-right: 5%;}
	}
	.box-news-home{
		.box-news-list-home{
			.list{
				width: 48.5%;
				&:nth-child(n + 3){display: none;}
				&:nth-child(2n){margin-right: 0;}
			}
		}
	}
	.box-news-list-home{
		.list{
			.date{width: 60px; padding: 10px;}
		}
	}

	.box-product{
		.list-product{
			margin-bottom: 78px;
			h3{margin-bottom: 15px;}
			p{margin-bottom: 30px; max-height: 95px; overflow: hidden;}
			.img-prod{
				figure{
					position: relative; height: 277px; overflow: hidden;
					img{position: absolute; top: 50%; left: 50%; @include transform(translate(-50%, -50%)); min-width: 100%; max-width: none; height: 100%;}
				}
			}
		}
	}
	.margtophead{margin-top: 65px;}
	.banner{
		margin-top: 65px; position: relative; height: 250px;
		> img{position: absolute; top: 50%; left: 50%; @include transform(translate(-50%, -50%)); min-width: 100%; max-width: none; height: 100%;}
	}
	.box-content{
		h4{margin-bottom: 25px;}
		h5{margin-bottom: 15px;}
		iframe{height: 380px;}
	}

	.box-two-list{
		.list{
			.left{width: 40%;}
			.right{width: 49%;}
		}
	}
	aside{padding-top: 45px;}
	article{
		padding-top: 45px;
		.box-title{margin-bottom: 30px;}
	}
	.box-link-reward{
		.list{
			padding-top: 40px; padding-bottom: 40px;
			a{right: 20px;}
		}
	}
	.box-board{
		.list-board{
			margin-bottom: 70px;
			h3{margin-bottom: 15px;}
			h6{margin-bottom: 0;}
			p{margin-bottom: 20px;}
			.img-board{width: 35%;}
			.desc-board{padding-top: 0; width: 54%;}
		}
		&.box-board-detail{
			.list-board{
				.img-board{width: 100%; margin-bottom: 40px;}
				.desc-board{
					width: 100%;
					p{margin-bottom: 20px;}
				}
			}
		}
	}
	.box-drag-nav-slide{
		&.ui-slider-horizontal{
			left: 90px; right: 90px;
			.ui-slider-handle{margin-left: -60px;}
		}
	}
	.box-drag-content{
		.list{
			.img-rekam{width: 45%;}
			.desc-rekam{
				width: 54%; padding-top: 30px;
				h3{margin-bottom: 15px;}
			}
		}
	}
	.box-content-career{padding-right: 0;}
	.career-link-send{
		margin-left: 50px;
		h1{line-height: 36px;}
		h6{line-height: 16px;}
	}
	.box-form-career{width: 100%;}
	.thankspage{
		min-height: 650px;
		.desc{
			width: 800px; max-width: 100%;
			h1{font-size: 40px;}
			h4{font-size: 22px; line-height: 28px;}
		}
	}

	.box-accordion{
		h5{margin-bottom: 0;}
	}

	.box-filter{
		&.box-filter-rekanan{
			select{width: 230px; margin-right: 20px;}
		}
	}

	.box-btn-tab{
		/*&.board-btn{
			.list{padding: 0 10px;}
		}
		&.board-btn-layanan{
			.list{width: 210px;}
			.inner-btn-tab{
				.bgdragg{width: 210px;}
			}
		}*/
		&.board-btn{
			.inner-btn-tab{
				.bgdragg{width: 33.3333333333333%;}
			}
			.list{width: 33.3333333333333%; padding: 0 10px;}
		}
	}

	.box-maps{margin-bottom: 40px;}
	.box-list-address{
		.col{margin-bottom: 52px;}
	}
	.slidenews{
		figure{
			img{position: absolute; top: 50%; left: 50%; @include transform(translate(-50%, -50%)); max-width: none; min-width: 100%; min-height: 100%; max-height: 300px;}
		}
		.desc{
			width: 376px; left: 135px; top: 50%; bottom: auto; @include transform(translateY(-50%));
			h1{font-size: 25px; margin-bottom: 15px;}
			h5{margin-bottom: 15px;}
			.btn-oval{height: 30px; line-height: 30px;}
		}
		.slick-prev, .slick-next{width: 20px; height: 50px;}
		.list{height: 250px;}
	}
	.box-news-vids{
		h4{margin-bottom: 20px;}
		p{max-height: 96px; overflow: hidden;}
		figure{
			height: 330px; position: relative; overflow: hidden;
			img{position: absolute; top:50%; left: 50%; @include transform(translate(-50%, -50%)); min-width: 100%; max-width: none; height: 100%;}
		}
		.left{width: 54%;}
		.right{width: 38%; padding-right: 20px;}
	}
	.paging{margin: 40px 0;}
	.box-news-list{
		.list{
			figure{
				.date{
					padding-top: 10px;
					h3{font-weight: 700;}
					span{line-height: 16px; display: block;}
				}
			}
			.desc{
				padding: 25px 40px 50px;
				.corner-link-bottom{left: 40px;}
			}
		}
	}
	.box-news-list .list .line-yl, .box-news-list .list footer .foot-top .box-link-foot h5:before, footer .foot-top .box-link-foot .box-news-list .list h5:before{left: -40px;}

	.box-detail-news{
		.box-title{
			margin-top: 0; margin-bottom: 35px;
			h6{line-height: 18px;}
		}
		.left-news{width: 64.2%;}
		.right-news{width: 30%;}
	}

	.box-qst-cpt{
		.left{width: 100%; margin-bottom: 20px;}
		.right{width: 100%; text-align: left;}
		.opt{display: block; margin-bottom: 10px;}
	}
	.box-sitemap{
		ul{padding-left: 38px;}
	}

	footer{
		.foot-top{
			.box-link-foot{
				.box-link-foot-left{
					width: 68.5%;
					.list-link-foot{
						width: 50%;
						&:nth-child(3n + 1){clear: inherit;}
						&:nth-child(2n + 1){clear: both;}
					}
				}
			}
		}
	}

	.testiHome {
		.wrapper {width: 905px;}
	}
	.wrap_popup{
		article{
			.box-title{
				margin: 0 auto 30px; width: 350px;
			}
		}
		/*.row_list {
			.col.col_mid_6{
				width: 70%;
			}
			.col.col_mid_4{
				width: 30%;
			}

		}*/
	}
}

@media(min-width: 768px) and (max-width: 1200px) {
	.wrap_popup{
		.row_list {
			.col.col_mid_6{
				width: 68%; margin-left: -8%;
			}

		}
	}
	.sliderblog-lg{
		.list{
			.desc{
				padding: 0 20px 20px;
				span{font-size: 14px;}
				h2{font-size: 30px;line-height: 36px;max-height: 72px;}
			}
		}
	}
	.blog-box{
		.thumb{
			.list{
				.desc{padding: 0 10px 10px;}
			}
		}
	}
	.ls-Testi {
		.ic {width: 100px; height: 100px; margin-top: -70px;}
		.slide {padding-top: 100px;}
		.box_testi {
			padding: 90px 35px 40px;
			h3 {font-size: 18px; margin-bottom: 10px;}
			h4 {font-size: 16px;}
			p {font-size: 14px;}
			&:before {left: 15px; right: 15px; top: 15px; bottom: 15px;}
		}
	}
}
@media(min-width: 	1px) and (max-width: 1200px) {

	.middle-nobanner {padding-top: 110px;}
	.section-telemarketing {
		padding: 60px 0;
		p {font-size: 16px;}
		.title-section {font-size: 20px;line-height: 30px;}
		.list-box {
			figure {width: 400px;}
			figcaption {
				width: calc(100% - 440px); padding-left: 50px;
				&:before {height: 4px;}
			}
		}
		.form-cek {
			display: flex; justify-content: space-between;
			.cek-number {
				width: calc(100% - 400px);
				form {
					position: relative; border-radius: 25px;width: 380px;
					input[type="text"],
					input[type="number"] {
						  height: 50px;
					}
					button {
						font-size: 14px; border-radius: 25px;
					}
				}
			}
			> p {width: 400px; margin: 0;}
		}
	}
}
